<template>
  <div>
    <PageHeader>
      <template #right>
        <b-button
            v-if="$allowPermission('agent:manage.promotion-code')"
            variant="primary" to="/promotion-code/create">
          <i class="uil-plus mr-1"></i>
          {{ $t('buttons.add') }}
        </b-button>
      </template>
    </PageHeader>

    <b-card>
      <PromotionCodeList />
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'PromotionCodePage',
  page: {
    title: 'รายการโค้ดโปรโมชั่น',
  },
  components: {
    PromotionCodeList: () => import('@components/promotion-code/List')
  }
}
</script>

<style scoped>

</style>